import React from 'react';
import { Link } from 'gatsby';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('ja');

const JaAboutBanner = ({ pageTitle, subTitle, bgImg, mobileBgImg }) => {
  return (
    <>
      <section id="page_bn" className="" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
        <div className="position-relative">
          <div className="position-absolute fixed-top text-white" style={{ zIndex: '8' }}>
            <div className="container">
              <div id="breadcrumb" className="">
                <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
                  <div className="">
                    <div className="col-12 pt-2">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                          <li className="breadcrumb-item">
                            <Link to="/ja" style={{ color: '#fff' }}>
                              {LANG.首頁}
                            </Link>
                          </li>
                          <li className="breadcrumb-item">{LANG.關於我們}</li>
                          <li className="breadcrumb-item">{pageTitle}</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right">
                <div className="dropdown">
                  <button
                    className="btn btn-sm dropdown-toggle bg-light"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {LANG.關於我們}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {[
                      { list: LANG.公司簡介, path: '/ja/about/jx' },
                      { list: LANG.經營理念, path: '/ja/about/business' },
                      { list: LANG.產品特色, path: '/ja/about/feature' },
                      { list: LANG.嚴選材料, path: '/ja/about/material' },
                      { list: LANG.生產設備, path: '/ja/about/facility' },
                      { list: LANG.品質與認證, path: '/ja/about/certificate' },
                      { list: LANG.板金工藝設計, path: '/ja/design' },
                    ].map((e) => {
                      return (
                        <Link className="dropdown-item" to={e.path} key={e.list}>
                          {e.list}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute fixed-bottom text-white bk-6">
            <div className="container position-absolute mb-5 fixed-bottom">
              <div className="col-12 h2">{pageTitle}</div>
              <div className="col-12">{subTitle}</div>
            </div>
          </div>
          <img className="d-none d-md-block main_rwd" src={bgImg} alt="background" />
          <img className="d-block d-md-none main_rwd" src={mobileBgImg} alt="background" />
        </div>
      </section>
    </>
  );
};

export default JaAboutBanner;
